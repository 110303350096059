import { memo } from 'react';
import { DialogTitle } from '@headlessui/react';

import Typography from 'components/typography';
import { useStyles } from 'components/drawer/styles';

const DrawerHeader = ({ title, description, size = 'md' }) => {
  const { dialogPanelHeader, dialogPanelHeaderText, headerTitle, headerDescription } = useStyles({ size });
  return (
    <div className={dialogPanelHeader}>
      <div className={dialogPanelHeaderText}>
        <DialogTitle className={headerTitle}>{title}</DialogTitle>
        {description && (
          <Typography variant="bodyL" className={headerDescription}>
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default memo(DrawerHeader);
