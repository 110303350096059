import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from 'sections/layout/sidebar/index';
import Drawer from 'components/drawer';

const SidebarWithDrawer = ({ open, onClose }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    onClose();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Drawer side="left" showClose={false} open={open} onClose={onClose} size="sm">
      <Sidebar />
    </Drawer>
  );
};

export default SidebarWithDrawer;
