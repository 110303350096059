import { lazy, memo, useState } from 'react';

import { isMobile, withSuspense } from 'utils';
import deviceName from 'utils/deviceName';
import { useTranslator } from 'utils/translator';
import MobileLogo from 'sections/layout/header/mobileLogo';
import SearchInput from 'components/search';
import { ReactComponent as NotificationIcon } from 'assets/icons/Notification.svg';

import NotificationButton from './NotificationButton';
import { useStyles } from './styles';

const ProfileMenu = withSuspense(lazy(() => import(`./ProfileMenu/${deviceName}`)));

const Header = () => {
  const { root, headerLeft, headerRight } = useStyles();

  const { t } = useTranslator();

  const [search, setSearch] = useState('');

  return (
    <>
      <div className={root}>
        {isMobile && <MobileLogo />}
        <div className={headerLeft}>
          <SearchInput
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={t('search_doctors_services_placeholder')}
            showHotkey
          />
        </div>
        <div className={headerRight}>
          <NotificationButton Icon={NotificationIcon} withBadge />
          <ProfileMenu />
        </div>
      </div>
    </>
  );
};

export default memo(Header);
