import { Outlet } from 'react-router-dom';

import { isMobile } from 'utils';

import Header from './header';
import Sidebar from './sidebar';
import { useStyles } from './styles';

const Layout = () => {
  const { root, sidebar, contentWrapper, header, content } = useStyles();

  return (
    <div className={root}>
      {!isMobile && (
        <div className={sidebar}>
          <Sidebar />
        </div>
      )}
      <div className={contentWrapper}>
        <div className={header}>
          <Header />
        </div>
        <main className={content}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
