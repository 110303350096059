import { createUseStyles } from 'react-jss';

import { mobileStyles } from 'assets/styles/helpers';

export const useStyles = createUseStyles(
  theme => ({
    root: {
      display: 'grid',
      gridTemplateColumns: '256px minmax(0, 1fr)',
      width: '100%',
      ...mobileStyles({ gridTemplateColumns: 'minmax(0, 1fr)' }),
    },
    sidebar: {
      minHeight: '100vh',
      width: '100%',
      maxWidth: 256,
      zIndex: 5,
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 32,
      ...mobileStyles({ gap: 0 }),
    },
    header: {
      width: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 4,
    },
    content: {
      width: '100%',
      marginBottom: '36px',
    },
  }),
  { name: 'Layout' },
);
