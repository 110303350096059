import {
  ACCESSIBILITY,
  ACCOUNT,
  CALENDAR,
  DASHBOARD,
  FACILITIES,
  MEMBERSHIP,
  NOTIFICATION,
  PATIENTS,
  PLATFORM,
  PRIVACY,
  REFERRALS,
  SCHEDULE,
  SECURITY,
  SMART_CONNECT,
  TRANSCRIPTIONS,
  TASKS,
  WALLET,
  PROFILE,
  SETTINGS,
  STAFF,
} from 'constants/path';
import { ReactComponent as TaskIcon } from 'assets/icons/twoTone/Tasks.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/twoTone/Wallet.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/twoTone/Calendar.svg';
import { ReactComponent as PatientsIcon } from 'assets/icons/twoTone/Patients.svg';
import { ReactComponent as ScheduleIcon } from 'assets/icons/twoTone/Schedule.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/twoTone/Dashboard.svg';
import { ReactComponent as ReferralsIcon } from 'assets/icons/twoTone/Referrals.svg';
import { ReactComponent as MembershipIcon } from 'assets/icons/twoTone/Membership.svg';
import { ReactComponent as FacilitiesIcon } from 'assets/icons/twoTone/Facilities.svg';
import { ReactComponent as SmartConnectIcon } from 'assets/icons/twoTone/SmartConnect.svg';
import { ReactComponent as CheckCircle } from 'assets/icons/twoTone/CheckCircle.svg';
import { ReactComponent as AccountIcon } from 'assets/icons/twoTone/Account.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/twoTone/Settings.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/twoTone/Security.svg';
import { ReactComponent as PrivacyIcon } from 'assets/icons/twoTone/Privacy.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/twoTone/Notification.svg';
import { ReactComponent as AccessibilityIcon } from 'assets/icons/twoTone/Accessibility.svg';
import { ReactComponent as TranscriptionsIcon } from 'assets/icons/twoTone/Transcriptions.svg';
import { ReactComponent as StaffIcon } from 'assets/icons/Staff.svg';
import { getRouteName } from 'utils';

export const navigation = [
  [
    {
      label: 'dashboard',
      href: DASHBOARD,
      Icon: DashboardIcon,
      name: getRouteName(DASHBOARD),
    },
    {
      label: 'calendar',
      href: CALENDAR,
      Icon: CalendarIcon,
      name: getRouteName(CALENDAR),
    },
    {
      label: 'schedule',
      href: SCHEDULE,
      Icon: ScheduleIcon,
      name: getRouteName(SCHEDULE),
      subMenus: [
        { label: 'schedule_1', href: `/1` },
        { label: 'schedule_2', href: `/2` },
        { label: 'schedule_3', href: `/3` },
      ],
    },
    {
      label: 'tasks',
      Icon: TaskIcon,
      name: getRouteName(TASKS),
      href: TASKS,
      // subMenus: [
      //   { label: 'Task 1', href: TASKS },
      //   { label: 'Task 2', href: TASKS },
      //   { label: 'Task 3', href: TASKS },
      //   { label: 'Task 4', href: TASKS },
      //   { label: 'Task 5', href: TASKS },
      // ],
    },
  ],
  [
    {
      label: 'facilities',
      href: FACILITIES,
      Icon: FacilitiesIcon,
      name: getRouteName(FACILITIES),
    },
    {
      label: 'patients',
      href: PATIENTS,
      Icon: PatientsIcon,
      name: getRouteName(PATIENTS),
    },
    {
      label: 'staff',
      href: STAFF,
      Icon: StaffIcon,
      name: getRouteName(PATIENTS),
    },
    {
      label: 'smart_connect',
      href: SMART_CONNECT,
      Icon: SmartConnectIcon,
      name: getRouteName(SMART_CONNECT),
    },
    {
      label: 'transcriptions',
      href: TRANSCRIPTIONS,
      Icon: TranscriptionsIcon,
      name: getRouteName(TRANSCRIPTIONS),
    },
  ],
  [
    {
      label: 'wallet',
      href: WALLET,
      Icon: WalletIcon,
      name: getRouteName(WALLET),
    },
    {
      label: 'membership',
      href: MEMBERSHIP,
      Icon: MembershipIcon,
      name: getRouteName(MEMBERSHIP),
    },
    {
      label: 'referrals',
      href: REFERRALS,
      Icon: ReferralsIcon,
      name: getRouteName(REFERRALS),
    },
  ],
];

export const settingsNavigation = [
  {
    label: 'profile_compliance',
    href: `${SETTINGS}${PROFILE}`,
    Icon: CheckCircle,
  },
  {
    label: 'account',
    href: `${SETTINGS}${ACCOUNT}`,
    Icon: AccountIcon,
  },
  {
    label: 'platform',
    href: `${SETTINGS}${PLATFORM}`,
    Icon: SettingsIcon,
  },
  {
    label: 'security',
    href: `${SETTINGS}${SECURITY}`,
    Icon: SecurityIcon,
  },
  {
    label: 'data_privacy',
    href: `${SETTINGS}${PRIVACY}`,
    Icon: PrivacyIcon,
  },
  {
    label: 'notification',
    href: `${SETTINGS}${NOTIFICATION}`,
    Icon: NotificationIcon,
  },
  {
    label: 'accessibility',
    href: `${SETTINGS}${ACCESSIBILITY}`,
    Icon: AccessibilityIcon,
  },
];
