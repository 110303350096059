import { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { DASHBOARD, SETTINGS } from 'constants/path';
import { useStyles } from 'sections/layout/header/styles';
import SidebarWithDrawer from 'sections/layout/sidebar/withDrawer';
import { ReactComponent as ArrowLeft } from 'assets/icons/ArrowLeft.svg';
import { ReactComponent as BurgerMenu } from 'assets/icons/Burger menu.svg';
import { ReactComponent as NurselinkLogoSmall } from 'assets/icons/Logo-small.svg';

const MobileLogo = () => {
  const { headerLogoWithAction } = useStyles();

  const { pathname } = useLocation();

  const isSettingsPage = SETTINGS === pathname;
  const isSettingsSubPage = pathname.includes(SETTINGS) && !isSettingsPage;

  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  return (
    <>
      <div className={headerLogoWithAction}>
        {isSettingsSubPage ? (
          <Link to={SETTINGS}>
            <ArrowLeft />
          </Link>
        ) : (
          <BurgerMenu onClick={() => setIsSidebarOpened(true)} />
        )}
        <Link to={DASHBOARD}>
          <NurselinkLogoSmall />
        </Link>
      </div>
      <SidebarWithDrawer open={isSidebarOpened} onClose={() => setIsSidebarOpened(false)} />
    </>
  );
};

export default memo(MobileLogo);
