import { createUseStyles } from 'react-jss';

import { generateStyles } from 'assets/styles/helpers';

export const useStyles = createUseStyles(
  theme => ({
    dialog: {
      position: 'relative',
      zIndex: 10,
    },
    overlay: {
      position: 'fixed',
      inset: 0,
      background: 'rgba(14, 15, 17, 0.20)',
      transitionProperty: 'opacity',
    },
    overlayEnter: {
      transitionTimingFunction: 'ease-in-out',
      transitionDuration: '.3s',
    },
    overlayEnterFrom: {
      opacity: 0,
    },
    overlayEnterTo: {
      opacity: 1,
    },
    dialogBody: {
      overflow: 'hidden',
      position: 'fixed',
      inset: 0,
    },
    dialogBodyWrapper: {
      overflow: 'absolute',
      position: 'fixed',
      inset: 0,
      '& > div': {
        pointerEvents: 'none',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: ({ side } = {}) => (side === 'left' ? 0 : 'initial'),
        right: ({ side } = {}) => (side === 'right' ? 0 : 'initial'),
        display: 'flex',
        maxWidth: '100%',
      },
    },
    transitionEnter: {
      transform: 'translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)',
      transition: 'all ease-in-out .3s',
    },
    transitionEnterFrom: {
      transform: ({ side } = {}) => `translateX(${side === 'right' ? '' : '-'}100%)`,
    },
    transitionEnterTo: {
      transform: 'translateX(0)',
    },
    dialogPanel: {
      pointerEvents: 'auto',
      [theme.screen.sm]: {
        display: 'flex',
        justifyContent: ({ side } = {}) => side === 'right' && 'end',
        pointerEvents: 'none',
        width: '100vw',
      },
    },
    dialogPanelWrapper: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      overflowY: 'auto',
      background: theme.palette.white,
      padding: '20px 28px 28px 28px',
      borderRadius: ({ side } = {}) => (side === 'right' ? '16px 0 0 16px' : '0 16px 16px 0'),
      width: ({ size }) => (size === 'md' ? 610 : 422),
      [theme.screen.sm]: {
        pointerEvents: 'auto',
        padding: '20px 16px 10px',
        width: '89% !important',
      },
    },
    dialogPanelHeader: {
      paddingRight: 30,
      paddingBottom: 32,
      [theme.screen.sm]: {
        gap: 44,
      },
    },
    headerTitle: {
      ...generateStyles(24, 32, 0, 500),
      [theme.screen.sm]: {
        ...generateStyles(22, 28, 0, 500),
      },
      display: 'flex',
      alignItems: 'center',
    },
    headerDescription: {
      color: theme.palette.neutral,
    },
    dialogPanelHeaderText: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
    arrowIcon: {
      width: '32px',
      height: '32px',
      padding: '6px',
      cursor: 'pointer',
      borderRadius: '50%',
      backgroundColor: theme.colors.grey[90],
      transition: 'all ease .3s',
      flexShrink: 0,
      position: 'absolute',
      top: 20,
      right: 20,
      '&:hover': {
        backgroundColor: theme.colors.grey[80],
      },
    },
    dialogContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'Drawer' },
);
