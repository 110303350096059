// generate font styles dynamically
import { isMobile } from 'utils';

export const generateStyles = (fontSize, lineHeight, letterSpacing = 0, fontWeight = 400) => {
  return {
    fontSize,
    lineHeight: `${lineHeight}px`,
    letterSpacing,
    fontWeight,
    whiteSpace: 'break-spaces',
  };
};

export const mobileStyles = styles => isMobile && styles;

export const desktopStyles = styles => !isMobile && styles;
