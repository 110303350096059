export const DASHBOARD = '/';
export const CALENDAR = '/calendar';
export const SCHEDULE = '/schedule';
export const TASKS = '/tasks';
export const FACILITIES = '/facilities';
export const PATIENTS = '/patients';
export const STAFF = '/staff';
export const SMART_CONNECT = '/smart-connect';
export const TRANSCRIPTIONS = '/transcriptions';
export const WALLET = '/wallet';
export const MEMBERSHIP = '/membership';
export const REFERRALS = '/referrals';
export const SETTINGS = '/settings';
export const PROFILE = '/profile';
export const ACCOUNT = '/account';
export const PLATFORM = '/platform';
export const SECURITY = '/security';
export const PRIVACY = '/privacy';
export const NOTIFICATION = '/notification';
export const ACCESSIBILITY = '/accessibility';
export const HELP_CENTER = '/help-center';

// TODO - replace from env, just for deploy issue now
export const SIGN_IN_PATH = `${process.env.REACT_APP_AUTH_URI}/signin`;

export const NOT_FOUND = '*';
