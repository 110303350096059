import { createUseStyles } from 'react-jss';

import { desktopStyles, mobileStyles } from 'assets/styles/helpers';

export const useStyles = createUseStyles(
  theme => ({
    root: {
      width: '100%',
      height: 68,
      padding: '14px 36px',
      background: theme.palette.white,
      borderBottom: `1px solid ${theme.colors.grey[70]}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16,
      ...mobileStyles({
        flexWrap: 'wrap',
        height: 'auto',
        gap: 14,
        padding: '16px 16px 14px',
      }),
    },
    headerLeft: {
      maxWidth: 480,
      width: '100%',
      ...desktopStyles({
        [theme.screen.lg]: {
          maxWidth: 320,
        },
      }),
      ...mobileStyles({
        order: 3,
        maxWidth: '100%',
      }),
    },
    headerRight: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
    },
    headerRightAvatar: {},
    profileWrapper: {
      padding: '8px 12px',
      display: 'flex',
      gap: 12,
    },
    profileAvatarWrapper: {},
    rateIcon: {
      marginBottom: 2,
    },
    rate: {
      color: theme.palette.neutral,
    },
    textWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    headerLogoWithAction: {
      display: 'flex',
      gap: 20,
    },
    notificationButton: {
      position: 'relative',
      outline: 'none',
      border: 'none',
      width: 44,
      height: 44,
      flexShrink: 0,
      borderRadius: 12,
      background: theme.colors.grey[95],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.colors.grey[10],
      transition: 'all ease .3s',
      cursor: 'pointer',
      '&:hover': {
        background: '#EBEFF2',
      },
      '&:disabled': {
        opacity: 0.4,
      },
      '& .badge': {
        position: 'absolute',
        top: 6,
        right: 6,
      },
    },
  }),
  { name: 'Header' },
);
